import { Component, ViewChild } from '@angular/core';
import { DynamicComp } from './directives/dynamic-comp.directive';
import { HeaderComponent } from './components/header/header.component';
import { singleSpaPropsSubject } from 'src/single-spa/single-spa-props';
import { PrivateBankingHeaderComponent } from './components/private-banking-header/private-banking-header.component';
import { TabService } from './services/tab.service';
import { SsoService } from './services/application.service';

@Component({
  selector: 'app-header-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  currentCompName: string = "sso-header";
  @ViewChild(DynamicComp, { static: true }) compRef!: DynamicComp;

  compHash: { name: string, compRef: any, environmentDataKey: string }[] = [
    { name: "sso-header", compRef: HeaderComponent, environmentDataKey: "ssoHeaderLinks" },
    { name: "private-banking-header", compRef: PrivateBankingHeaderComponent, environmentDataKey: "ssoHeaderLinks" }

  ];

  constructor(private tabService: TabService,
    private appService: SsoService
  ) {}

  ngOnInit() {
    console.log("Checking for UserType");
    this.injectComponent();
    singleSpaPropsSubject.subscribe((props: any) => {
      this.injectComponent();
    });
    this.appService.init()
    // singleton init once
    this.tabService.init();
  }

  injectComponent() {
    if (!this.currentCompName) return;

    const componentObj = this.compHash.find(comp => comp.name === this.currentCompName);
    if (!componentObj) return;

    const viewRef = this.compRef.viewContainerRef;
    viewRef.clear();
    viewRef.createComponent(componentObj.compRef);
  }

}
